.App {
    text-align: center;
}

.App-logo {
    animation: App-logo-spin infinite 20s linear;
    height: 40vmin;
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

.APP H1 {
    font-size: 1.5em;
}

.nav .active {
    background: #EEE !important;
}

.card-wrapper {
    margin: 0 0 40px 0;
}

.button {
    display: table-cell;
    padding: 6px 10px;
    border-radius: 5px;
    border: 1px solid #007bff;
    color: #fff;
    font-weight: bold;
    background: #007bff;
}

.button:hover {
    color: #fff;
    #background: #2bb3ff;
    text-decoration: none;
}

.card-body {
    overflow: hidden;
}
